import React from 'react';
import dayjs from 'dayjs';
import Footer, { FooterLeft, FooterRight } from '../../../components/layouts/Footer/Footer';

const DefaultFooterTemplate = () => {
	return (
		<Footer>
			<FooterLeft>
				<p>© {dayjs().year()} BotFarm</p>
			</FooterLeft>
			<FooterRight>
				<p>Version 1.0.0</p>
			</FooterRight>
		</Footer>
	);
};
export default DefaultFooterTemplate;
