import React, { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardHeaderChild,
	CardTitle,
} from '../../../../components/ui/Card';
import usersDb from '../../../../mocks/db/users.db';
import productsDb from '../../../../mocks/db/products.db';
import getFirstLetter from '../../../../utils/getFirstLetter';

interface ICommentItemProps {
	image?: string;
	firstName: string;
	username: string;
	productName: string;
	comment: string;
	time: string;
}
const CommentItem: FC<ICommentItemProps> = (props) => {
	const { image, firstName, username, productName, comment, time } = props;
	return (
		<div className='flex w-full gap-4'>
			<div className='flex-shrink-0'>
				{image && <img src={image} alt={firstName} className='h-16 w-16 rounded-full' />}
				{!image && (
					<div className='flex aspect-square h-16 w-16 items-center justify-center rounded-full bg-blue-500/20 text-2xl text-blue-500'>
						{getFirstLetter(firstName)}
					</div>
				)}
			</div>
			<div className='flex-grow'>
				<div>
					<b>{firstName}</b> <span className='text-gray-500'>@{username}</span>
				</div>
				<div className='mb-2'>
					<span className='text-gray-500'>On</span> <b>{productName}</b>
				</div>
				<div>{comment}</div>
			</div>
			<div className='flex-shrink-0'>{time}</div>
		</div>
	);
};
CommentItem.defaultProps = {
	image: undefined,
};

const CommentPartial = () => {
	return (
		<Card className='h-full'>
			<CardHeader>
				<CardHeaderChild>
					<CardTitle>Bot Farm Progress</CardTitle>
				</CardHeaderChild>
				<CardHeaderChild>
					<div className='flex -space-x-2 overflow-hidden'>
						{/* <img
							src={usersDb[0].image?.thumb}
							alt={usersDb[0].firstName}
							className='h-8 w-8 rounded-full ring-2 ring-white'
						/> */}
						
					</div>
				</CardHeaderChild>
			</CardHeader>
			<CardBody>
				<div className='flex flex-col gap-4'>
					<CommentItem
						image={usersDb[3].image?.thumb}
						firstName={usersDb[0].firstName}
						username={usersDb[0].username}
						productName="BotFarm Progress"
						comment='Getting Started is slow but making progress. We have this new landing page and login works, mostly.'
						time='2024-03-21 09:51 pm'
					/>
		
				</div>
			</CardBody>
		</Card>
	);
};

export default CommentPartial;
