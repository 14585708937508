import dayjs, { Dayjs } from 'dayjs';
import usersDb, { TUser } from './users.db';
import {
	Image19Thumb,
	Image20Thumb,
	Image21Thumb,
	Image22Thumb,
	Image23Thumb,
	Image24Thumb,
	Image25Thumb,
	Image26Thumb,
} from '../../assets/images';

export type TProject = {
	id: string;
	status: string;
	name: string;
	description: string;
	dueDate?: Dayjs | string | number;
	image?: string | string[];
	users?: TUser[];
	comments?: {
		id: string;
		user: TUser;
		comment: string;
	}[];
};


//This is where you add more projects
const projectsDb: TProject[] = [
	{
		id: '1',
		status: 'In Progress',
		name: 'Home Tasks',
		description: 'This is where we track our Home Tasks',
		dueDate: dayjs().add(12, 'day'),
		users: [usersDb[0], usersDb[1],],
		image: [Image19Thumb, Image20Thumb,],
		comments: [
		],
	},
	
];

export default projectsDb;
