import { useEffect, useState } from 'react';
import usersDb, { TUser } from '../mocks/db/users.db';
import { head, set } from 'lodash';

const BotNetwork = (username: string) => {

    const getBotList = async () => {
        var server = localStorage.getItem('server')||'';
        var userNameOrMail = localStorage.getItem('userNameOrMail')||'';
        var password = localStorage.getItem('password')||'';


        var response = await fetch(server + '/get_bot_list', {
            method: 'POST',
            mode: 'cors', 
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': password,
                'X-Api-User': userNameOrMail
            },
            body: JSON.stringify({}) 
        });

        if (response.status === 200) {
            const data = await response.json(); 
            
            return data; // Return the fetched weather data
        } else {
            // Handle errors (e.g., throw an error for the caller to catch)
            throw new Error('Fetch Bot List Failed failed'); 
        }
    }



    const getWeather = async () => { 

        var server = localStorage.getItem('server')||'';
        var userNameOrMail = localStorage.getItem('userNameOrMail')||'';
        var password = localStorage.getItem('password')||'';


        var response = await fetch(server + '/get_current_weather', {
            method: 'POST',
            mode: 'cors', 
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': password,
                'X-Api-User': userNameOrMail
            },
            body: JSON.stringify({}) 
        });

        if (response.status === 200) {
            const data = await response.json(); 
            
            return data; // Return the fetched weather data
        } else {
            // Handle errors (e.g., throw an error for the caller to catch)
            throw new Error('Weather data fetch failed'); 
        }
    }


    
	const getCheckUser = async (userNameOrMail: string, password: string,server:string) => {
		var isUserLoggedIn = false;
        setIsLoading(true);
    

        //Get profile, if we get past it, our API key is working
        var response = await fetch(server+'/getProfile', {
            method: 'POST',
            mode: 'cors', 
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': password,
                'X-Api-User': userNameOrMail
            },
            body: JSON.stringify({            })
        });

        if (response.status === 200) {
            isUserLoggedIn = true;
            // setResponse(userdata as TUser);
            response.json().then((data) => {
                setResponse(data[userNameOrMail] as TUser);
                
                //store server,userNameOrMail, password for other pages to use
                localStorage.setItem('ProfileData', data);
                localStorage.setItem('server', server);
                localStorage.setItem('userNameOrMail', userNameOrMail);
                localStorage.setItem('password', password);
                localStorage.setItem('_id', "65fbd0488cf864039432fbde");
                localStorage.setItem('firstName', "Joe");
                localStorage.setItem('lastName', "Ronie");
                localStorage.setItem('role', "Admin");
                localStorage.setItem('username', "Joe", );

            });
        }
        
        setIsLoading(false);
		return new Promise((resolve, reject) => {
			if (isUserLoggedIn) resolve(true);
			else reject(new Error('The password is invalid!', { cause: 'password' }));
		});
	};

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [response, setResponse] = useState<TUser | undefined>();
	
    
    return { response, isLoading, getCheckUser, getWeather, getBotList};

};

	



export default BotNetwork;


